import * as actions from './recordActivity.actions';
import { EntityState, createEntityAdapter, Update } from '@ngrx/entity';
import { createFeatureSelector, createSelector, Store} from '@ngrx/store';
import { RecordActivityModel } from '@smarttask-common/src/lib/models/RecordActivity/RecordActivityModel';


export const recordActivityAdapter = createEntityAdapter<RecordActivityModel>({
  selectId: x => x.activity_id
});
export interface recordActivityState extends EntityState<RecordActivityModel>{
};

export const initialState: recordActivityState = recordActivityAdapter.getInitialState({
}); 


export function recordActivityReducers(
  state: recordActivityState = initialState,
  action: actions.recordActivityActions
){
  switch(action.type){

    case actions.recordActivityActionTypes.ADD_ACTIVITY: 
      return recordActivityAdapter.addOne(action.activity, state);

    case actions.recordActivityActionTypes.ADD_ACTIVITIES:
      return recordActivityAdapter.addMany(action.activities,state);

    case actions.recordActivityActionTypes.UPSERT_ACTIVITY:
      return recordActivityAdapter.upsertOne(action.activity,state);
      
    case actions.recordActivityActionTypes.UPSERT_ACTIVITIES:
      return recordActivityAdapter.upsertMany(action.activities,state);
  
    case actions.recordActivityActionTypes.UPDATE_ACTIVITY:
      return recordActivityAdapter.updateOne(action.activity,state);

    case actions.recordActivityActionTypes.UPDATE_ACTIVITIES:
      return recordActivityAdapter.updateMany(action.activities,state);

    case actions.recordActivityActionTypes.DELETE_ACTIVITY:
      // if(state.selectedGTaskId == action.id){
      //   state.selectedGTaskId = undefined;
      // }
      return recordActivityAdapter.removeOne(action.id,state);

    case actions.recordActivityActionTypes.DELETE_ACTIVITIES:
      // if(action.ids.indexOf(state.selectedGTaskId) > -1){
      //   state.selectedGTaskId = undefined;
      // }
      return recordActivityAdapter.removeMany(action.ids,state);

    case actions.recordActivityActionTypes.DELETE_ALL_ACTIVITIES:
      return recordActivityAdapter.removeMany(action.ids,state);

    case actions.recordActivityActionTypes.DELETE_ALL:
      return recordActivityAdapter.removeAll(state);

    default:
      return state;
  }
}

export const getrecordActivityState = createFeatureSelector<recordActivityState>('recordActivity');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = recordActivityAdapter.getSelectors(getrecordActivityState);


