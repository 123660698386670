import {Action} from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { RecordActivityModel } from '@smarttask-common/src/lib/models/RecordActivity/RecordActivityModel';

export enum recordActivityActionTypes {
  ADD_ACTIVITY = '[RecordActivity] Create RecordActivity',
  UPSERT_ACTIVITY = '[RecordActivity] Upsert RecordActivity',
  ADD_ACTIVITIES = '[RecordActivity] Add recordActivity',
  UPSERT_ACTIVITIES = '[RecordActivity] Upsert recordActivity',
  UPDATE_ACTIVITY = '[RecordActivity] Update RecordActivity',
  UPDATE_ACTIVITIES = '[RecordActivity] Update recordActivity',
  DELETE_ACTIVITY = '[RecordActivity] Delete RecordActivity',
  DELETE_ACTIVITIES = '[RecordActivity] Delete recordActivity',
  DELETE_ALL_ACTIVITIES = '[RecordActivity] Delete All recordActivity',
  DELETE_ALL = '[RecordActivity] Delete All'
}

export class AddOne implements Action{
  readonly type = recordActivityActionTypes.ADD_ACTIVITY;
  constructor(public activity: RecordActivityModel){

  }
}

export class AddMany implements Action{
  readonly type = recordActivityActionTypes.ADD_ACTIVITIES;
  constructor(public activities: Array<RecordActivityModel>){

  }
}

export class UpsertOne implements Action {
  readonly type = recordActivityActionTypes.UPSERT_ACTIVITY;

  constructor(
    public activity: RecordActivityModel) {}
}

export class UpsertMany implements Action {
  readonly type = recordActivityActionTypes.UPSERT_ACTIVITIES;
  constructor(public activities: Array<RecordActivityModel> ) {}
}

export class UpdateOne implements Action{
  readonly type = recordActivityActionTypes.UPDATE_ACTIVITY;
  constructor(public activity: Update<RecordActivityModel>){
    
  }
}

export class UpdateMany implements Action{
  readonly type = recordActivityActionTypes.UPDATE_ACTIVITIES;
  constructor(public activities: Array<Update<RecordActivityModel>>){
    
  }
}

export class DeleteOne implements Action{
  readonly type = recordActivityActionTypes.DELETE_ACTIVITY;
  constructor(
    public id
  ){}
}

export class DeleteMany implements Action{
  readonly type = recordActivityActionTypes.DELETE_ACTIVITIES;
  constructor(
    public ids: Array<any>
  ){}
}

export class DeleteAll implements Action{
  readonly type = recordActivityActionTypes.DELETE_ALL_ACTIVITIES;
  constructor(public ids: Array<any>){}
}

export class DeleteState implements Action{
  readonly type = recordActivityActionTypes.DELETE_ALL;
  constructor(
  ){}
}

export type recordActivityActions = 
  AddOne 
  | AddMany 
  | UpsertOne 
  | UpsertMany 
  | DeleteOne 
  | DeleteMany 
  | DeleteAll
  | DeleteState
  | UpdateOne
  | UpdateMany;